<template>
  <div>
    <a-modal width="1100px" :title="Title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
      @cancel="cancel">
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-row>
          <a-col :span="10">
            <a-tree v-if="visible" :load-data="onLoadData" v-model:checkedKeys="checkedKeys" checkable
              :auto-expand-parent="autoExpandParent" :replace-fields="replaceFields" :tree-data="treeData"
              @check="onCheck" @expand="onExpand" @select="onSelect" />
          </a-col>
          <a-col :span="14">
            <a-table style="margin-top: 10px" :columns="columns" :data-source="sourceData" row-key="id" :pagination="false">
              <template #operation="{ text, record, index }">
                {{ record.name ? record.name : (record.realName ? record.realName : "") }}
              </template>
            </a-table>
          </a-col>
        </a-row>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { childrenTree, userToDepartmentUserDeep } from '@/api/system'
const columns = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: '部门',
    dataIndex: 'departmentName',
    key: 'departmentName',
  },
  {
    title: "姓名",
    dataIndex: "name",
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "index",
  props: {
    FormData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      replaceFields: {
        key: 'id',
        title: 'name',
        type: 'type'
      },
      sourceData: [],
      delData: [],
      autoExpandParent: true,
      checkedKeys: [],
      treeData: [],
      columns,
      visible: false,
      confirmLoading: false,
      Title: '',
      node: ''
    };
  },
  created() { },
  methods: {
    onLoadData(treeNode) {
      return new Promise(resolve => {
        const param = {
          departmentId: treeNode.eventKey ? treeNode.eventKey : ''
        }
        childrenTree(param).then(res => {
          const dataList = []
          res.data.map(res => {
            if (res.type == 'user') {
              dataList.push({ ...res, isLeaf: true })
            } else {
              dataList.push({ ...res })
            }
          })
          treeNode.dataRef.children = dataList;
          this.treeData = [...this.treeData];
          return resolve();
        })
      });
    },
    onCheck(checkedKeys, checkedNodes,) {
      this.checkedKeys = checkedKeys;
      // console.log(checkedNodes, '是否点击');
      // console.log(checkedKeys, '所有选中的数据');
      // console.log(checkedNodes.node.dataRef, '选中数据');
      if (checkedNodes.checked) {
        //部门
        if (checkedNodes.node.dataRef.type == 'department') {
          const params_api = {
            departmentId: checkedNodes.node.dataRef.id
          }
          userToDepartmentUserDeep({ ...params_api }).then((res) => {
            res.data.forEach(res => {
              this.sourceData.push({ id: res.userId, name: res.realName, departmentName: res.department })
            })
            const resList = new Map();
            this.sourceData=  this.sourceData.filter((a) => !resList.has(a.id) && resList.set(a.id, 1))
          })
        }
        //用户
        if (checkedNodes.node.dataRef.type == 'user') {
          this.sourceData.push(checkedNodes.node.dataRef)
        }
      } else {
        //部门
        if (checkedNodes.node.dataRef.type == 'department') {
          const params_api = {
            departmentId: checkedNodes.node.dataRef.id
          }
          userToDepartmentUserDeep({ ...params_api }).then((res) => {
            this.delData = []
            res.data.forEach(res => {
              this.delData.push({ id: res.userId, name: res.realName, departmentName: res.department })
            })
            this.delData.forEach((delres) => {
              this.sourceData.forEach((datares, index) => {
                if (delres.id == datares.id) {
                  this.sourceData.splice(index, 1)
                }
              })
            })
          })
        }
        //用户
        if (checkedNodes.node.dataRef.type == 'user') {
          this.sourceData.forEach((res, index) => {
            if (res.id == checkedNodes.node.dataRef.id) {
              this.sourceData.splice(index, 1)
            }
          })
        }
      }

    },
    cancel() {
      this.visible = false;
    },
    handleOk() {
      this.$emit('sourceData', this.sourceData)
      this.visible = false;
    },
    showModal(val) {
      this.Title = val
      this.visible = true;
      childrenTree().then(res => {
        this.treeData = []
        this.treeData = res.data
        // this.treeData = res.data.map(item => ({ ...item, isLeaf: true }))
      })
      this.sourceData = []
      this.checkedKeys = []
      if (this.FormData) {
        // this.sourceData = this.FormData
        this.FormData.map(res => {
          if (res.departmentName) {
            this.sourceData.push({ ...res })
          } else {
            this.sourceData.push({ ...res, departmentName: res.department })
          }
        })
        this.sourceData.forEach(res => {
          this.checkedKeys.push(res.id)
        })
      }
    }
  },
});
</script>
<style lang="less">

</style>
